body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.lineheightcustom {
  line-height: 80px !important;
}

.grianetcolor {
  background: #0052d4; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #6fb1fc,
    #4364f7,
    #0052d4
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #6fb1fc,
    #4364f7,
    #0052d4
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

button[type="submit"] {
  -webkit-appearance: button;
  background-color: #063688 !important;
  background-image: none;
}

.css-1nmdiq5-menu {
  z-index: 999;
}

.tox .tox-editor-container {
  z-index: 0;
}

.profiledetailscls {
  font-size: 16px;
  font-weight: 500;
  color: #383535;
  line-break: anywhere;
}

label.profileheddingcls {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: #767676;
}

.bg_images {
  background-image: url("../public/assets/img/main_image.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
}

.epr_q53mwh.epr_vl50xg.epr_-3yva2a.epr_c90x4z.epr_-sisw4f.epr_mw4zr1.epr_-kg0voo.epr_iogimd.epr_wtwvf4.epr_-lzq3oa {
  display: none;
}

.bgs_starts {
  background-image: url("../public/assets/img/bgchats.png");
  background-repeat: no-repeat;
  background-size: cover;
}


.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
  background: #ee695e !important;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 5px;
}

.rdrDayToday .rdrDayNumber span:after {
  content: '';
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 18px;
  height: 2px;
  border-radius: 2px;
  background: #ee695e !important;
}